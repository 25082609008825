// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tag_icon__2hrWV{\n    height: 24px;\n    width: max-content;\n    min-width: 24px;\n    border-radius: 3px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #FFFFFF;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.tag_numberIcon__3wrf3{\n    height: 24px;\n    width: 24px;\n    min-width: 24px;\n    border-radius: 3px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #FFFFFF;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.tag_text__15aeq{\n    margin: 0;\n    padding: 4px 16px;\n    -webkit-user-select: none;\n            user-select: none;\n}", "",{"version":3,"sources":["webpack://src/components/Tags/tag.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,SAAS;IACT,iBAAiB;IACjB,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":[".icon{\n    height: 24px;\n    width: max-content;\n    min-width: 24px;\n    border-radius: 3px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #FFFFFF;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.numberIcon{\n    height: 24px;\n    width: 24px;\n    min-width: 24px;\n    border-radius: 3px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #FFFFFF;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.text{\n    margin: 0;\n    padding: 4px 16px;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "tag_icon__2hrWV",
	"numberIcon": "tag_numberIcon__3wrf3",
	"text": "tag_text__15aeq"
};
export default ___CSS_LOADER_EXPORT___;
