// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#loader_page__3-xiJ{\n    width: 100%;\n    height: 100%;\n}\n\n#loader_page__3-xiJ .loader_wrapper__3YZ8p{\n    position: absolute;\n    top: calc(50% - 80px);\n    left: calc(50% - 42px);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: max-content;\n    height: max-content;\n}\n\n#loader_page__3-xiJ .loader_loader__CU0Cg{\n    font-size: 52px;\n    color: #f27b60;\n}\n\n#loader_page__3-xiJ .loader_text__2R3Yt{\n    font-size: 16px;\n    margin-top: 32px;\n    color: #909090;\n}", "",{"version":3,"sources":["webpack://src/components/Loaders/loader.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":["#page{\n    width: 100%;\n    height: 100%;\n}\n\n#page .wrapper{\n    position: absolute;\n    top: calc(50% - 80px);\n    left: calc(50% - 42px);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: max-content;\n    height: max-content;\n}\n\n#page .loader{\n    font-size: 52px;\n    color: #f27b60;\n}\n\n#page .text{\n    font-size: 16px;\n    margin-top: 32px;\n    color: #909090;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "loader_page__3-xiJ",
	"wrapper": "loader_wrapper__3YZ8p",
	"loader": "loader_loader__CU0Cg",
	"text": "loader_text__2R3Yt"
};
export default ___CSS_LOADER_EXPORT___;
