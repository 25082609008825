export const SAVE_CARGO = "SAVE_CARGO";
export const SAVE_CARGO_SUCCESS = "SAVE_CARGO_SUCCESS";
export const SAVE_CARGO_FAIL = "SAVE_CARGO_FAIL";

export const CARGO = "CARGO";
export const CARGO_SUCCESS = "CARGO_SUCCESS";
export const CARGO_FAIL = "CARGO_FAIL";

export const ONE_CARGO = "ONE_CARGO";
export const ONE_CARGO_SUCCESS = "ONE_CARGO_SUCCESS";
export const ONE_CARGO_FAIL = "ONE_CARGO_FAIL";

export const GET_MY_CARGO = "GET_MY_CARGO";
export const GET_MY_CARGO_SUCCESS = "GET_MY_CARGO_SUCCESS";
export const GET_MY_CARGO_FAIL = "GET_MY_CARGO_FAIL";

export const SAVE_LOAD_SPACE = "SAVE_LOAD_SPACE";
export const SAVE_LOAD_SPACE_SUCCESS = "SAVE_LOAD_SPACE_SUCCESS";
export const SAVE_LOAD_SPACE_FAIL = "SAVE_LOAD_SPACE_FAIL";

export const GET_MY_LOADING_SPACES = "GET_MY_LOADING_SPACES";
export const GET_MY_LOADING_SPACES_SUCCESS = "GET_MY_LOADING_SPACES_SUCCESS";
export const GET_MY_LOADING_SPACES_FAIL = "GET_MY_LOADING_SPACES_FAIL";

export const ADD_STOCK = "ADD_STOCK";
export const ADD_STOCK_SUCCESS = "ADD_STOCK_SUCCESS";
export const ADD_STOCK_FAIL = "ADD_STOCK_FAIL";

export const MY_STOCKS = "MY_STOCKS";
export const MY_STOCKS_SUCCESS = "MY_STOCKS_SUCCESS";
export const MY_STOCKS_FAIL = "MY_STOCKS_FAIL";

export const GET_LOADING_SPACE = "GET_LOADING_SPACE";
export const GET_LOADING_SPACE_SUCCESS = "GET_LOADING_SPACE_SUCCESS";
export const GET_LOADING_SPACE_FAIL = "GET_LOADING_SPACE_FAIL";

export const STOCK = "STOCK";
export const STOCK_SUCCESS = "STOCK_SUCCESS";
export const STOCK_FAIL = "STOCK_FAIL";

export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL";

export const SET_CARGO_FILTERS = "SET_CARGO_FILTERS";

export const SET_PANES = "SET_PANES";

export const SET_ACTIVE_PANE = "SET_ACTIVE_PANE";

export const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER";

export const GET_CITIES_OF_COUNTRY = "GET_CITIES_OF_COUNTRY";
export const GET_CITIES_OF_COUNTRY_SUCCESS = "GET_CITIES_OF_COUNTRY_SUCCESS";
export const GET_CITIES_OF_COUNTRY_FAIL = "GET_CITIES_OF_COUNTRY_FAIL";

export const PANES = "PANES";
export const PANES_SUCCESS = "PANES_SUCCESS";
export const PANES_FAIL = "PANES_FAIL";

export const SET_ACTIVE_KEY = "SET_ACTIVE_KEY";

export const UPDATE_CARGO = "UPDATE_CARGO";
export const UPDATE_CARGO_SUCCESS = "UPDATE_CARGO_SUCCESS";
export const UPDATE_CARGO_FAIL = "UPDATE_CARGO_FAIL";

export const ONE_LOAD_SPACE = "ONE_LOAD_SPACE";
export const ONE_LOAD_SPACE_SUCCESS = "ONE_LOAD_SPACE_SUCCESS";
export const ONE_LOAD_SPACE_FAIL = "ONE_LOAD_SPACE_FAIL";

export const UPDATE_LOAD_SPACE = "UPDATE_LOAD_SPACE";
export const UPDATE_LOAD_SPACE_SUCCESS = "UPDATE_LOAD_SPACE_SUCCESS";
export const UPDATE_LOAD_SPACE_FAIL = "UPDATE_LOAD_SPACE_FAIL";

export const ONE_STOCK = "ONE_STOCK";
export const ONE_STOCK_SUCCESS = "ONE_STOCK_SUCCESS";
export const ONE_STOCK_FAIL = "ONE_STOCK_FAIL";

export const COMPANY = "COMPANY";
export const COMPANY_SUCCESS = "COMPANY_SUCCESS";
export const COMPANY_FAIL = "COMPANY_FAIL";

export const COMPANIES_TO_CONFIRM = "COMPANIES_TO_CONFIRM";
export const COMPANIES_TO_CONFIRM_SUCCESS = "COMPANIES_TO_CONFIRM_SUCCESS";
export const COMPANIES_TO_CONFIRM_FAIL = "COMPANIES_TO_CONFIRM_FAIL";

export const SET_CITIES_FILTER = "SET_CITIES_FILTER";
