// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".collapse_multipleIcon__155c8{\n    position: absolute;\n    left: 0px;\n    top: 11px;\n}", "",{"version":3,"sources":["webpack://src/components/Collapse/collapse.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;AACb","sourcesContent":[".multipleIcon{\n    position: absolute;\n    left: 0px;\n    top: 11px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multipleIcon": "collapse_multipleIcon__155c8"
};
export default ___CSS_LOADER_EXPORT___;
