export default {
  hr: {
    sign_in: "Prijava",
    mail: "E-mail adresa",
    pass: "Lozinka",
    sign_in_butt: "Prijavite se",
    no_account: "nemate račun?",
    make_account: "Napravite račun",
    invalid_mail: "Neispravan email...",
    compulsory_field: "Ovo polje je obavezno...",
    on_click_error: "Izgleda da je došlo do greške.",
    register: "Registriraj se",
    user: "Korisnik",
    register_name: "Vaše ime",
    register_surname: "Vaše prezime",
    confirm_pass: "Potvrdite lozinku",
    phone_number: "Broj telefona",
    my_languages: "Moji jezici",
    company: "Poduzeće",
    company_name: "Naziv poduzeća",
    country: "Država",
    city: "Mjesto",
    post_number: "Poštanski broj",
    vat_number: "OIB",
    year_founded: "Godina osnivanja firme",
    website: "Web stranica",
    add_mail_butt: "Dodaj e-mail",
    company_number: "Broj",
    add_number_butt: "Dodaj broj",
    upload_doc_butt: "Učitaj dokument",
    have_account: "imate račun?",
    fetch_user_error: "Dogodila se greška kod dohvata korisnika...",
    fetch_data_error: "Dogodila se greška kod dohvata podataka...",
    ad_search: "Pretraga oglasa",
    ad_create: "Stvaranje oglasa",
    company_search: "Poduzeća",
    information: "Informacije",
    cargo: "Teret",
    loading_space: "Utovarni prostor",
    warehouses: "Skladišta",
    croatian: "Hrvatski",
    english: "Engleski",
    german: "Njemački",
    loading: "Učitavanje",
    sign_out_butt: "Odjava",
    all_ads: "Svi",
    date: "Datum",
    departure: "Polazište",
    arrival: "Odredište",
    length: "Dužina",
    weight: "Težina",
    width: "Širina",
    load_unload: "Utov/Istov",
    cargo_search: "Pretraga tereta",
    from: "Od",
    country_choice: "Izbor države",
    all_countries: "Sve",
    to: "Do",
    vehicle_choice: "Izbor vozila",
    vehicle_type: "Vrsta vozila",
    vehicle_upgrade: "Nadogradnja",
    vehicle_equipment: "Oprema vozila",
    time: "Vrijeme",
    week: "Tjedan",
    month: "Mjesec",
    quarter: "Kvartal",
    year: "Godina",
    min_length: "Min. dužina",
    max_length: "Max. dužina",
    min_weight: "Min. težina",
    max_weight: "Max. težina",
    incl_blocked_users: "Uključi blokirane korisnike",
    auction: "Aukcija",
    show_auction: "Prikazati aukcije",
    clean_butt: "Očisti",
    apply_filters_butt: "Primjeni filtere",
    no_flag: "NE",
    yes_flag: "DA",
    contact_person: "Kontakt osoba",
    open_profile: "Idi",
    my_warehouses: "Moja skladišta",
    warehouse_equip: "Oprema skladišta",
    warehouse_name: "Naziv skladišta",
    warehouse_search: "Pretražite skladišta…",
    add_warehouse: "Dodaj skladište",
    save_butt: "Spremi",
    min_wh_surface: "Min. površina",
    max_wh_surface: "Max. površina",
    pictures: "Slike",
    add_picture: "Pritisnite + ikonu da biste dodali slike Dopuštene ekstenzije su: .jpg .png",
    picture_low: "Slike",
    search_company: "Pretražite poduzeća…",
    blocked: "Blokirani",
    basic_info: "Osnovne informacije",
    fax_number: "Broj faksa",
    use_conditions: "Uvjeti korištenja aplikacije",
    use_instructions: "Upute za korištenje",
    location: "Područje",
    save_error: "Dogodila se greška kod spremanja...",
    load_unload_places: "Mjesta utovara i istovara",
    load_places: "Mjesta utovara",
    unload_places: "Mjesta istovara",
    cargo_spec: "Opis tereta",
    cargo_equip_exchange: "Razmjena utovarne opreme",
    price: "Cijena",
    vehicle_type_required: "Potreban tip vozila",
    added_numbers: "dodana broja",
    added_emails: "dodana email-a",
    description: "Napomena",
    departure_arrival_places: "Mjesta polazišta i odredišta",
    departure_places: "Mjesta polazišta",
    arrival_places: "Mjesta odredišta",
    vehicle_sort: "Tip vozila",
    trailer_length: "Dužina priključenog vozila",
    trailer_weight: "Nosivost priključenog vozila",
    cargo_type: "Vrsta robe",
    cargo_length: "Dužina tereta",
    cargo_width: "Širina tereta",
    cargo_weight: "Težina tereta",
    bid_starting_price: "Početna cijena",
    bid_min_stake: "Min. Ulog",
    date_from: "Datum od",
    date_to: "Datum do",
    time_from: "Vrijeme od",
    time_to: "Vrijeme do",
    vehicle_length: "Dužina vozila",
    vehicle_load: "Nosivost vozila",
    nearby_distance: "U krugu od _ km",
    cargo_shape: "Oblik robe",
    transportation_type: "Vrsta prijevoza",
    presence: "Prisutnost",
    load_system: "Utovarni sustav",
    vehicle_number: "Broj vozila",
    own_vehicles: "Vlastita vozila",
    vehicle_upgrades: "Nadogradnje vozila",
    add_user_butt: "Dodaj korisnika",
    contact_people: "Kontakt osobe",
    add_butt: "Dodaj",
    documents: "Dokumenti",
    user_edit: "Uredi korisnika",
    user_delete: "Izbriši korisnika",
    change_pass: "Izmjeni lozinku",
    pass_no_match: "Lozinke se ne podudaraju!",
    upload_butt: "Učitaj",
    founded: "Osnovano",
    cargo_add_title: "Oglas tereta",
    want_logout: "Jeste li sigurni da se želite odjaviti?",
    quit: "Odustani",
    services: "Usluge",
    home_page: "Početna stranica",
    hide: "Sakrij",
    expand: "Proširi",
    published_cargo: "Objavljeni tereti",
    published_load_space: "Objavljeni utovari",
    published_warehouses: "Objavljena skladišta",
    my_cargo: "Moji tereti",
    my_load_space: "Moji utovari",
    cities: "Gradovi",
    cities_are_not_selected: "Gradovi nisu odabrani",
    back: "Natrag",
    warehouse: "Skladište",
    search: "Pretraži",
    choose_date: "Odaberi datum",
    departure_has_to_have_date: "Datum polaska je obvezan!",
    min_one_departure_and_arrival_with_date: "Navesti barem jedno polazište i odredište sa datumom",
    details: "Detaljno",
    reject: "Odbaci",
    search_companies: "Pretražite poduzeća ",
    edit: "Uredi",
    finish: "Završi",
    copy: "Kopiraj",
    add_create_by_company: "Oglas objavila firma",
    time_period: "Vremenski period",
    nk_create_add: "Moji oglasi",
    languages: "Jezici",
    name: "Ime",
    surname: "Prezime",
    vehicle: "Vozilo",
    at_least_country: "Navesti min jednu državu.",
    date_missing:"Datum nije naveden",
    id_number: "ID broj",
    address: "Adresa",
    success: "Uspješno",
    free_from: "Slobodan od",
    free_to: "Slobodan do",
    surface: "Površina"
  },
  DE: {
    sign_in: "Anmelden",
    mail: "E-Mail-Adresse",
    pass: "Passwort",
    sign_in_butt: "Anmeldung",
    no_account: "kein Benutzerkonto?",
    make_account: "Benutzerkonto erstellen",
    invalid_mail: "Die E-Mail-Adresse ist ungültig.",
    compulsory_field: "Das ist ein Pflichtfeld.",
    on_click_error: "Es ist ein Fehler aufgetreten.",
    register: "Registrieren",
    user: "Benutzer",
    register_name: "Vorname",
    register_surname: "Nachname",
    confirm_pass: "Passwort bestätigen",
    phone_number: "Telefonnummer",
    my_languages: "Meine Sprachen",
    company: "Firma",
    company_name: "Firmenwortlaut",
    country: "Land",
    city: "Stadt",
    post_number: "Postleitzahl",
    vat_number: "Mehrwertsteuernummer",
    year_founded: "Gründungsjahr",
    website: "Website",
    add_mail_butt: "E-Mail-Adresse hinzufügen",
    company_number: "Firmennummer",
    add_number_butt: "Hinzufügen",
    upload_doc_butt: "Dokument hochladen",
    have_account: "haben Sie ein Benutzerkonto?",
    fetch_user_error: "Es ist ein Fehler beim Abrufen des Benutzers aufgetreten.",
    fetch_data_error: "Es ist ein Fehler beim Abrufen der Daten aufgetreten.",
    ad_search: "Anzeige suchen",
    ad_create: "Anzeige erstellen",
    company_search: "Firma",
    information: "Information",
    cargo: "Fracht",
    loading_space: "Laderaum",
    warehouses: "Lager",
    croatian: "Kroatisch",
    english: "Englisch",
    german: "Deutsch",
    loading: "Laden",
    sign_out_butt: "Abmeldung",
    all_ads: "Alle",
    date: "Datum",
    departure: "Abfahrt",
    arrival: "Ankunft",
    length: "Länge",
    weight: "Gewicht",
    width: "Breite",
    load_unload: "Laden/Entladen",
    cargo_search: "Fracht suchen",
    from: "Von",
    country_choice: "Auswahl Land",
    all_countries: "Alle",
    to: "Bis",
    vehicle_choice: "Auswahl Fahrzeug",
    vehicle_type: "Fahrzeugtyp",
    vehicle_upgrade: "Fahrzeug-Upgrade",
    vehicle_equipment: "Fahrzeugausstattung",
    time: "Zeit",
    week: "Woche",
    month: "Monat",
    quarter: "Quartal",
    year: "Jahr",
    min_length: "Mindeslänge",
    max_length: "Maximallänge",
    min_weight: "Mindestgewicht",
    max_weight: "Maximalgewicht",
    incl_blocked_users: "inkl gesperrter Benutzer",
    auction: "Auktion",
    show_auction: "Auktionen anzeigen",
    clean_butt: "Löschen",
    apply_filters_butt: "Filter anwenden",
    no_flag: "Nein",
    yes_flag: "Ja",
    contact_person: "Kontaktperson",
    open_profile: "öffnen",
    my_warehouses: "Meine Lager",
    warehouse_equip: "Lagerausstattung",
    warehouse_name: "Lagername",
    warehouse_search: "Lager suchen",
    add_warehouse: "Lager hinzufügen",
    save_butt: "speichern",
    min_wh_surface: "Mindestlagerfläche",
    max_wh_surface: "Maximallagerfläche",
    pictures: "Bilder",
    add_picture: "Klicken Sie auf das + Symbol, um Bilder hinzuzufügen. Zulässige Erweiterungen sind: .jpg; .png",
    picture_low: "Bild",
    search_company: "Firma suchen",
    blocked: "gesperrt",
    basic_info: "Basisinformation",
    fax_number: "Faxnummer",
    use_conditions: "Nutzungsbedingungen",
    use_instructions: "Gebrauchsanweisungen",
    location: "Standort",
    save_error: "Es ist ein Fehler beim Speichern aufgetreten.",
    load_unload_places: "Belade- und Entladeort",
    load_places: "Beladeort",
    unload_places: "Entladeort",
    cargo_spec: "Bezeichnung der Fracht",
    cargo_equip_exchange: "Austausch der Frachtausrüstung",
    price: "Preis",
    vehicle_type_required: "Fahrzeugtyp erforderlich",
    added_numbers: "hinzugefügte Telefonnummern",
    added_emails: "hinzugefügte E-Mail-Adressen",
    description: "Beschreibung",
    departure_arrival_places: "Abfahrts- und Ankunftsort",
    departure_places: "Abfahrtsort",
    arrival_places: "Ankunftsort",
    vehicle_sort: "Fahrzeugtyp",
    trailer_length: "Anhängerlänge",
    trailer_weight: "Anhängergewicht",
    cargo_type: "Frachtart",
    cargo_length: "Frachtlänge",
    cargo_width: "Frachtbreite",
    cargo_weight: "Frachtgewicht",
    bid_starting_price: "Startpreis",
    bid_min_stake: "Mindestgebot",
    date_from: "Datum von",
    date_to: "Datum bis",
    time_from: "Zeit von",
    time_to: "Zeit bis",
    vehicle_length: "Fahrzeuglänge",
    vehicle_load: "Fahrzeuglast",
    nearby_distance: "im Umkreis von _ km",
    cargo_shape: "Frachtform",
    transportation_type: "Transporttyp",
    presence: "Anwesenheit",
    load_system: "Ladesystem",
    vehicle_number: "Fahrzeuganzahl",
    own_vehicles: "eigene Fahrzeuge",
    vehicle_upgrades: "Fahrzeug-Upgrades",
    add_user_butt: "Benutzer hinzufügen",
    contact_people: "Kontaktperson",
    add_butt: "hinzufügen",
    documents: "Dokumente",
    user_edit: "Benutzer bearbeiten",
    user_delete: "Benutzer löschen",
    change_pass: "Passwort ändern",
    pass_no_match: "Passwörter stimmen nicht überein!",
    upload_butt: "Hochladen",
    founded: "Gegründet",
    cargo_add_title: "Cargo-Werbung",
    want_logout: "Möchten Sie sich wirklich abmelden?",
    quit: "Stornieren",
    services: "Dienstleistungen",
    home_page: "Startseite",
    hide: "Ausblenden",
    expand: "Erweitern",
    published_cargo: "Veröffentlichte Frachtanzeigen",
    published_load_space: "Veröffentlichte Laderaumanzeigen",
    published_warehouses: "Veröffentlichte Lageranzeigen",
    my_cargo: "Meine Frachtanzeigen",
    my_load_space: "Meine Laderaumanzeigen",
    cities: "Städte",
    cities_are_not_selected: "Städte sind nicht ausgewählt",
    back: "Zurück",
    warehouse: "Lagerhaus",
    search: "Suche",
    choose_date: "Datum wählen",
    departure_has_to_have_date: "Abreisedatum ist obligatorisch!",
    min_one_departure_and_arrival_with_date: "Mindestens ein Abreisedatum und ein Ankunftsdatum sind erforderlich!",
    details: "Einzelheiten",
    reject: "Ablehnen",
    search_companies: "Unternehmen suchen",
    edit: "Bearbeiten",
    finish: "Ziel",
    copy: "Kopieren",
    add_create_by_company: "Anzeige erstellt von",
    time_period: "Zeitraum",
    nk_create_add: "Meine Anzeigen",
    languages: "Sprachen",
    name: "Name",
    surname: "Nachname",
    vehicle: "Fahrzeug",
    at_least_country: "Geben Sie mindestens ein Land an!",
    date_missing:"Datum nicht angegeben",
    id_number: "ID-Numer",
    address: "Adresse",
    success: "Erfolgreich!",
    free_from: "Frei von",
    free_to: "Frei zu",
    surface: "Oberfläche"
  },
  EN: {
    sign_in: "Sign in",
    mail: "Email",
    pass: "Password",
    sign_in_butt: "Sign in",
    no_account: "no account?",
    make_account: "Make account ",
    invalid_mail: "Invalid email...",
    compulsory_field: "This is a compulsory field...",
    on_click_error: "There seems to be an error",
    register: "Register",
    user: "User",
    register_name: "Name",
    register_surname: "Surname",
    confirm_pass: "Confirm password",
    phone_number: "Phone number",
    my_languages: "My languages",
    company: "Company",
    company_name: "Company name",
    country: "Country",
    city: "City",
    post_number: "Post number",
    vat_number: "Tax number",
    year_founded: "Year founded",
    website: "Website",
    add_mail_butt: "Add email address",
    company_number: "company_number",
    add_number_butt: "Add",
    upload_doc_butt: "Upload document",
    have_account: "you have account?",
    fetch_user_error: "There seemed to be an error while retrieving users...",
    fetch_data_error: "There seemed to be an error while retrieving data...",
    ad_search: "Search ads",
    ad_create: "Create ads",
    company_search: "Search companies",
    information: "Information",
    cargo: "Cargo",
    loading_space: "Loading space",
    warehouses: "Warehouses",
    croatian: "Croatian",
    english: "English",
    german: "German",
    loading: "Loading",
    sign_out_butt: "Sign out",
    all_ads: "All",
    date: "Date",
    departure: "Departure",
    arrival: "Arrival",
    length: "Length",
    weight: "Weight",
    width: "Width",
    load_unload: "Load/Unload",
    cargo_search: "Search cargo",
    from: "From",
    country_choice: "Choose a country",
    all_countries: "All",
    to: "To",
    vehicle_choice: "Vehicle",
    vehicle_type: "Vehicle type",
    vehicle_upgrade: "Vehicle upgrade",
    vehicle_equipment: "Vehicle equipment",
    time: "Time",
    week: "Week",
    month: "Month",
    quarter: "Quarter",
    year: "Year",
    min_length: "Min length",
    max_length: "Max length",
    min_weight: "Min weight",
    max_weight: "Max weight",
    incl_blocked_users: "Include blocked users",
    auction: "Auction",
    show_auction: "Show auctions",
    clean_butt: "Clear",
    apply_filters_butt: "Apply filters",
    no_flag: "No",
    yes_flag: "Yes",
    contact_person: "Contact person",
    open_profile: "Open profile",
    my_warehouses: "My warehouses",
    warehouse_equip: "Warehouse equipment",
    warehouse_name: "Warehouse name",
    warehouse_search: "Search warehouses...",
    add_warehouse: "Add a warehouse",
    save_butt: "Add",
    min_wh_surface: "Min. surface",
    max_wh_surface: "Max. surface",
    pictures: "Pictures",
    add_picture: "Click + symbol to add pictures Allowed extensions are .jpg and .png",
    picture_low: "Pictures",
    search_company: "Search companies",
    blocked: "Blocked",
    basic_info: "Basic information",
    fax_number: "Fax number",
    use_conditions: "Terms of use",
    use_instructions: "Use instructions",
    location: "Location",
    save_error: "There seemed to be an error while saving...",
    load_unload_places: "Load and unload places",
    load_places: "Load places",
    unload_places: "Unload places",
    cargo_spec: "Cargo description",
    cargo_equip_exchange: "Cargo equipment exchange",
    price: "Price",
    vehicle_type_required: "Required vehicle type",
    added_numbers: "added numbers",
    added_emails: "added emails",
    description: "Description",
    departure_arrival_places: "Departure and arrival places",
    departure_places: "Departure places",
    arrival_places: "Arrival places",
    vehicle_sort: "Vehicle type",
    trailer_length: "Trailer length",
    trailer_weight: "Trailer weight",
    cargo_type: "Cargo type",
    cargo_length: "Cargo length",
    cargo_width: "Cargo width",
    cargo_weight: "Cargo weight",
    bid_starting_price: "Bid starting price",
    bid_min_stake: "Min. bid amount",
    date_from: "Date from",
    date_to: "Date to",
    time_from: "Time from",
    time_to: "Time to",
    vehicle_length: "Vehicle length",
    vehicle_load: "Vehicle load",
    nearby_distance: "Nearby distance",
    cargo_shape: "Cargo shape",
    transportation_type: "Transportation type",
    presence: "Presence",
    load_system: "Load system",
    vehicle_number: "Vehicle number",
    own_vehicles: "Own vehicles",
    vehicle_upgrades: "Vehicle upgrades",
    add_user_butt: "Add user",
    contact_people: "Contact person",
    add_butt: "Add",
    documents: "Documents",
    user_edit: "Edit user",
    user_delete: "Delete user",
    change_pass: "Change password",
    pass_no_match: "Passwords don't match!",
    upload_butt: "Upload",
    founded: "Founded",
    cargo_add_title: "Cargo ad title",
    want_logout: "Are you sure you want to sign out?",
    quit: "Cancel",
    services: "Services",
    home_page: "Starting page",
    hide: "Hide",
    expand: "Expand",
    published_cargo: "Published cargo ads",
    published_load_space: "Published loadspace ads",
    published_warehouses: "Published warehouse ads",
    my_cargo: "My cargo ads",
    my_load_space: "My loadspace ads",
    cities: "Cities",
    cities_are_not_selected: "Cities are not selected",
    back: "Back",
    warehouse: "Warehouse",
    search: "Search",
    choose_date: "Choose date",
    departure_has_to_have_date: "Departure date is obligatory!",
    min_one_departure_and_arrival_with_date: "At least one departure, date and one arrival are necessary!",
    details: "Details",
    reject: "Reject",
    search_companies: "Search companies",
    edit: "Edit",
    finish: "Finish",
    copy: "Copy",
    add_create_by_company: "Ad created by",
    time_period: "Time period",
    nk_create_add: "My ads",
    languages: "Languages",
    name: "Name",
    surname: "Surname",
    vehicle: "Vehicle",
    at_least_country: "At least one country.",
    date_missing: "Date is not specified",
    id_number: "ID",
    address: "Address",
    success: "Success!",
    free_from: "Free from",
    free_to: "Free to",
    surface: "Surface"
  }
}